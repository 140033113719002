/* NEW NOTIFY ME (got rid of magnificpopup) */

// modal toggle click re inits notify me
Array.from(document.querySelectorAll('.js-notifyme-modal-toggle')).forEach(
  (toggle) => {
    toggle.addEventListener('click', () => {
      initNotifyMe();
    });
  },
);

function initNotifyMe() {
  Array.from(document.querySelectorAll('.js-notify-me__pdp-form')).forEach(
    (button) => {
      const input = button.querySelector('[name="notifyme-email"]');
      input.setAttribute('placeholder', 'Enter your email address');

      button.addEventListener('submit', (event) => {
        event.preventDefault();
      });
    },
  );

  Array.from(
    document.querySelectorAll('.js-notify-me__pdp-form-button'),
  ).forEach((form) => {
    form.addEventListener('click', (event) => {
      event.preventDefault();
      //var form = this;
      var form = event.target.parentElement.parentElement;
      var error = form.querySelector('.errorlist');
      var success = form.nextElementSibling;
      var button = form.querySelector('.js-notify-me__pdp-form-button');
      var inputEmail = form.querySelector('[name="notifyme-email"]');
      var signupText = form
        .closest('.notify-me__parent')
        .querySelector('.notify-me__description__sign-up');

      if (
        form instanceof HTMLFormElement &&
        error instanceof HTMLDivElement &&
        success instanceof HTMLDivElement &&
        button instanceof HTMLInputElement &&
        inputEmail instanceof HTMLInputElement &&
        signupText instanceof HTMLParagraphElement
      ) {
        if (!inputEmail.value) return;
        // Disable the submit button
        button.disabled = true;
        // Post the data and handle result

        const dataToSend = new FormData(form);
        const formJSON = Object.fromEntries(dataToSend.entries());
        fetch(form.getAttribute('action'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(formJSON),
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((res) => {
                // Enable the button
                button.disabled = false;
                // If there is an error with the email, give a specific error msg
                if (res.email) {
                  error.innerText = res.email;
                  // Otherwise, use generic msg
                } else {
                  error.innerText =
                    'We had trouble processing your request. Please try again later!';
                }
                return -1;
              });
            }
            return 0;
          })
          .then((response) => {
            if (response == -1) return;
            form.hidden = true;
            success.hidden = false;
            signupText.hidden = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    });
  });
}

initNotifyMe();
