var SteelSeries = SteelSeries || {};

if (cms_edit_mode) {
  function check_cms_mode() {
    setTimeout(function () {
      if (CMS && CMS.settings && CMS.settings.mode == 'structure') {
        var html = document.querySelector('html');
        if (html) {
          html.classList.remove('cms-content-mode');
          html.classList.add('cms-structure-mode');
        }
      } else {
        var html = document.querySelector('html');
        if (html) {
          html.classList.remove('cms-structure-mode');
          html.classList.add('cms-content-mode');
        }
      }
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  // your page initialization code here
  // the DOM will be available here
  check_cms_mode();
  let domBodies = document.querySelectorAll('body');
  for (i = 0; i < domBodies.length; i++) {
    domBodies[i].addEventListener('click', function () {
      const clickedElement = event.target,
        matchingChild = clickedElement.closest(
          '.cms_toolbar-item-cms-mode-switcher a',
        );
      if (matchingChild) check_cms_mode();
    });
  }
}
